<template>
  <div>
    <el-card>
      <el-form
        ref="form"
        :model="form"
        label-width="110px"
        :inline="false"
        size="normal"
      >
        <el-tag>企业基本信息</el-tag>
        <el-form-item v-show="form.name" label="企业名称" prop="name">
          <span>{{ form.name }}</span>
        </el-form-item>
        <el-form-item v-show="form.logo" label="企业logo" prop="logo">
          <!-- <imgup :action="action" :banner="form.logo" @event="setImg"></imgup> -->
          <img :src="baseurl + yu + form.logo" alt="" style="height: 180px;">
        </el-form-item>
        <el-form-item v-show="form.website" label="公司官网" prop="website">
          <span>{{ form.website }}</span>
        </el-form-item>
        <el-form-item label="实际办公地址" prop="city">
          <span>{{ p_set(form.province) }}</span><span v-show="p_set(form.province)">-</span><span>{{ c_set(form.city) }}</span><span v-show="c_set(form.city)">-</span><span>{{ form.address }}</span>
        </el-form-item>
        <el-form-item label="公司人数" prop="peopleName">
          <span>{{ form.peopleName }}</span>
        </el-form-item>
        <el-form-item label="融资情况" prop="financingName">
          <span>{{ form.financingName }}</span>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-checkbox-group v-model="form.type" :max="3">
            <el-checkbox
              v-for="(item, index) in $store.state.config &&
                $store.state.config.type"
              :key="index"
              :label="index"
            >{{ item }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="行业" prop="business">
          <el-checkbox-group v-model="form.business" :max="3">
            <el-checkbox
              v-for="(item, index) in $store.state.config &&
                $store.state.config.business"
              :key="index"
              :label="index"
            >{{ item }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="业务区域" prop="serverArea">
          <el-checkbox-group v-model="form.serverArea" :max="3">
            <el-checkbox
              v-for="(item, index) in this.$store.state.enterprise_config &&
                this.$store.state.enterprise_config.enterpriseServerArea"
              :key="index"
              :label="item.key + ''"
            >{{ item.value }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="企业介绍" prop="intro">
          <span>{{ form.intro }}</span>
        </el-form-item>
        <el-form-item label="公司简介" prop="companyDesc">
          <el-col :span="8" :offset="0">
            <span>{{ form.companyDesc }}</span>

          </el-col>
        </el-form-item>
        <el-form-item label="产品介绍">
          <el-col :span="8" :offset="0">

            <span>{{ form.productDesc }}</span>
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="产品图片">
          <imgups
            :action="action"
            :list="this.form.productImages"
            @changes="c_img"
          />
        </el-form-item>
        <div>
          <el-tag> 企业认证信息</el-tag>
          <h1>企业信息</h1>
          <el-form-item label="公司执照">
            <el-col :span="10" :offset="0">
              <el-image style="width:100%" :src="baseurl + yu + form.extend.license" :preview-src-list="[baseurl + yu + form.extend.license]">
                <div slot="error">
                  <!-- <i class="el-icon-picture-outline"></i> -->
                </div>
              </el-image>

            </el-col>
          </el-form-item>
          <!-- <el-form-item label="授权人信息" style="">
          <p style="color: #8c939d; line-height: 6px">
            提交认证后，扬帆出海审核专员将联系授权人确认信息。授权人也作为企业在扬帆出海
          </p>
        </el-form-item> -->
          <h1>授权人信息</h1>
          <el-form-item label="授权人姓名">
            <el-col :span="17" :offset="0">
              <span>{{ form.extend.authorizer }}</span>
            </el-col>
          </el-form-item>
          <el-form-item label="授权人身份证号">
            <el-col :span="17" :offset="0">
              <!-- <el-input v-model="form.extend.authorizerCardNum"></el-input> -->
              <span>{{ form.extend.authorizerCardNum }}</span>
            </el-col>
          </el-form-item>
          <el-form-item label="授权人手机号">
            <el-col :span="17" :offset="0">
              <!-- <el-input v-model="form.extend.authorizerPhone"></el-input> -->
              <span>{{ form.extend.authorizerPhone }}</span>
            </el-col>
          </el-form-item>
          <el-form-item label="授权人身份证">
            <div class="box1">
              <Img
                :banner.sync="form.extend.authorizerCardFront"
                :action="action"
                :tip="'正面'"
              />
            </div>
            <div class="box1">
              <Img
                :banner.sync="form.extend.authorizerCardBack"
                :action="action"
                :tip="'反面'"
              />
            </div>
          </el-form-item>
        </div>

        <el-form-item>
          <el-button type="primary" size="medium" @click="onSubmit">{{
            '返回'
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import imgup from '../../components/upImg/index.vue'
import rules from '../../utils/rules'
import Img from './enterprise_cert_list/img.vue'
import imgups from '../../components/uplist/index.vue'
export default {
  components: {
    imgup,
    imgups,
    Img
  },
  data() {
    return {
      id: null,
      form: {
        name: '',
        logo: '',
        website: '',
        province: '',
        city: '',
        address: '',
        people: '',
        financing: '',
        type: '1',
        business: [],
        serverArea: [],
        companyDesc: '',
        productDesc: '',
        productImages: [],
        extend: {
          name: '',
          organizingCode: '',
          registerAddress: '',
          license: '',
          authorizer: '',
          authorizerCardNum: '',
          authorizerPhone: '',
          authorizerWechat: '',
          authorizerEmail: '',
          authorizerCardFront: '',
          authorizerCardBack: ''
        }
      },

      rule: {
        name: [rules.req('请输入企业名称')],
        logo: [rules.req('请输入企业logo')],
        website: [rules.req('请输入公司官网')],
        city: [rules.req('请输入实际公司地址')],
        people: [rules.req('请输入公司人事')],
        financing: [rules.req('请输入融资情况')],
        type: [rules.req('请输入类型')],
        business: [rules.req('请输入行业')],
        serverArea: [rules.req('请输入业务区域')],
        companyDesc: [rules.req('请输入公司简介')]
      },
      action: {
        action: 'enterprise'
      },
      province: null,
      city: null,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain
    }
  },
  computed: {
    // show_ex(){
    //   // console.log(JSON.stringify({}));
    //   if(JSON.stringify(this.form.extend)=='{}'){
    //     return false
    //   }else{
    //     return true
    //   }
    // }
  },
  created() {
    this.getp()
    this.getform()
  },
  methods: {
    c_img(val) {
      this.form.productImages = val
    },
    p_set(val) {
      if (!this.province) {
        return ''
      } else {
        //   console.log(this.province);
        return this.province.find(item => item.code == val) && this.province.find(item => item.code == val).name
      }
    },
    c_set(val) {
      if (!this.city) {
        return ''
      } else {
        return this.city.find(item => item.code == val) && this.city.find(item => item.code == val).name
      }
    },
    async getp() {
      const res = await this.$store.dispatch('getProvince')
      this.province = res.data
      this.id && this.getc(1)
    },
    async getc(val) {
      if (!val) this.form.city = ''
      const { data: res } = await this.$store.dispatch(
        'getCity',
        this.form.province
      )
      this.city = res
    },
    op() {
      this.getc()
    },
    async onSubmit() {
      this.$router.push('/enterprise_cert_list')
      return
    },
    async getform() {
      // console.log(this.$route.params.id + 'sadsadsad')
      if (this.$route.params.id || sessionStorage.getItem('show_id')) {
        var id = this.$route.params.id || sessionStorage.getItem('show_id')
        sessionStorage.setItem('show_id', id)
        this.id = id
        this.$http
          .get('/admin/Enterprise/getById?id=' + id)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              // console.log(res);
              var yyds = res.data.productImages.split(',')
              var url = []
              yyds.forEach(item => {
                if (item) {
                  url.push({
                    name: '1',
                    url: item
                  })
                }
              })
              this.form = {
                ...res.data,
                business: res.data.business.split(','),
                serverArea: res.data.serverArea.split(','),
                type: res.data.type.toString().split(','),
                productImages: url
              }
              // console.log(this.form)
              this.form.id = id * 1
              //  this.form.initViews =
              //   Math.floor(Math.random() * (200 - 50 + 1)) + 50
            } 
          })
      }
    },
    setImg(val) {
      this.form.logo = val
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  display: inline-block;
  width: 300px;
}
.box1 {
  display: inline-block;
  margin-right: 10px;
}
.el-tag--light {
  margin-bottom: 50px;
  line-height: 30px;
  height: 30px;
  text-align: center;
  font-size: 14px;
}
h1 {
  margin: 30px;
}
.is-always-shadow {
  margin-top: 10px;
}
</style>
